import React, { useEffect, createElement } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import HubspotForm from '../hubspotForm/hubspotForm';

const ProtocolCards = ({ type, from, exams, otherBatteries, countryCode }) => {
  const allCards = [
    ...exams,
    {
      title: 'Espirometría',
      fields: {
        icon: 'icon-spirometry',
      },
    },
    {
      title: 'Audiometría',
      fields: {
        icon: 'icon-ear-audiometry',
      },
    },
    {
      title: 'Electrocardiograma',
      fields: {
        icon: 'icon-ekg-heart',
      },
    },
    {
      title: 'Oftalmología',
      fields: {
        icon: <svg id="uuid-e64cb9b0-0f0c-4df7-aae8-0d6c67a2230f" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 53.23">
        <g id="uuid-2fde1e19-42b0-49a5-ba7c-04112a4867c7" data-name="Capa 1">
          <g>
            <path d="m55.38,28.32c.34-.43.61-.86.88-1.29l.09-.15c.1-.16.15-.35.14-.53,0-.11,0-.22-.04-.33-.35-1.09-1.08-1.87-1.73-2.55l-.14-.15c-4.04-4.32-8.09-7.58-12.39-9.97-4.47-2.48-8.74-3.6-13.05-3.41-4.07.18-8.12,1.5-12.38,4.04-4.32,2.57-11.58,9.69-11.74,9.91l-.08.1c-1.59,2.15-1.73,2.75.18,4.85,6.3,6.95,12.4,11.09,19.18,13.04,1.86.54,3.75.8,5.66.8,2.43,0,4.9-.44,7.37-1.31,4.76-1.68,9.15-4.46,13.84-8.77,1.45-1.33,2.94-2.7,4.22-4.3Zm-18.66,11.37c-4.04,1.43-8.06,1.59-11.93.47-6.45-1.86-12.28-5.84-18.35-12.53-.28-.31-.93-1.03-.93-1.21,0-.18.44-.78.87-1.36l.03-.04c.39-.42,7.29-7.13,11.27-9.49,4-2.39,7.78-3.62,11.54-3.79.28-.01.56-.02.84-.02,3.69,0,7.38,1.05,11.26,3.21,4.13,2.29,8.04,5.44,11.95,9.62l.14.15c.48.51.94,1,1.21,1.57-.21.33-.41.64-.64.93-1.19,1.49-2.57,2.75-4.05,4.11-4.49,4.12-8.68,6.78-13.2,8.38Z" fill="#223a70" stroke-width="0"/>
            <path d="m29.75,17.66c-.09,0-.19,0-.28,0h-.03c-.05,0-.12,0-.18,0-4.04.56-6.67,2.83-7.63,6.56-.69,2.71-.05,5.47,1.76,7.57,1.71,1.97,4.22,3.13,6.71,3.13.27,0,.55-.01.82-.04,2.79-.29,5.46-2.18,6.8-4.82,1.31-2.57,1.21-5.49-.26-8.03-1.66-2.87-4.33-4.38-7.73-4.38Zm6.38,11.59c-1.07,2.11-3.19,3.62-5.39,3.85-2.17.23-4.47-.72-5.98-2.47-1.44-1.67-1.93-3.79-1.38-5.95.76-2.99,2.81-4.74,6.07-5.21.02,0,.04,0,.06,0,2.91-.08,5.03,1.1,6.41,3.48,1.17,2.03,1.25,4.27.21,6.31Z" fill="#223a70" stroke-width="0"/>
            <path d="m15.19,51.43H1.8v-12.97c0-.5-.4-.9-.9-.9s-.9.4-.9.9v13.87c0,.5.4.9.9.9h14.29c.5,0,.9-.4.9-.9s-.4-.9-.9-.9Z" fill="#223a70" stroke-width="0"/>
            <path d="m57.1,37.56c-.5,0-.9.4-.9.9v12.97h-12.55c-.5,0-.9.4-.9.9s.4.9.9.9h13.45c.5,0,.9-.4.9-.9v-13.87c0-.5-.4-.9-.9-.9Z" fill="#223a70" stroke-width="0"/>
            <path d="m57.1,0h-14.29c-.5,0-.9.4-.9.9s.4.9.9.9h13.39v12.97c0,.5.4.9.9.9s.9-.4.9-.9V.9c0-.5-.4-.9-.9-.9Z" fill="#223a70" stroke-width="0"/>
            <path d="m.9,15.67c.5,0,.9-.4.9-.9V1.8h12.55c.5,0,.9-.4.9-.9s-.4-.9-.9-.9H.9c-.5,0-.9.4-.9.9v13.87c0,.5.4.9.9.9Z" fill="#223a70" stroke-width="0"/>
          </g>
        </g>
      </svg>,
      },
    },
    {
      title: 'Antropometría',
      fields: {
        icon: 'icon-anthropometry',
      },
    },
    {
      title: 'Rayos X',
      fields: {
        icon: <svg id="uuid-d902c34e-3377-43fe-a68d-f35b7fe8c514" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.17 54.17">
        <g id="uuid-ce017fa3-ebad-4499-8ffd-f14cacd29154" data-name="Capa 1">
          <g>
            <path d="m53.27,0H.9c-.5,0-.9.4-.9.9v52.37c0,.5.4.9.9.9h52.37c.5,0,.9-.4.9-.9V.9c0-.5-.4-.9-.9-.9Zm-.9,18.19c-.4-.09-.72-.25-1-.5-1.62-1.5-3.62-2.6-6.47-3.59-.97-.34-1.96-.63-2.95-.93-.67-.2-1.33-.4-1.99-.61-2.43-.78-3.94-2.43-4.63-5.04-.46-1.75-.68-3.63-.7-5.72h17.73v16.39ZM19.2,1.8c-.01,2.05-.24,3.93-.7,5.73-.71,2.76-2.41,4.45-5.36,5.33l-1.13.33c-1.63.47-3.32.96-4.94,1.66-1.71.73-3.17,1.63-4.47,2.73-.28.24-.54.4-.79.51V1.8h17.4ZM1.8,52.37V19.96c.69-.14,1.31-.47,1.96-1.02,1.16-.99,2.47-1.79,4.01-2.45,1.52-.65,3.16-1.13,4.74-1.58l1.14-.33c3.57-1.06,5.73-3.22,6.6-6.61.5-1.94.74-3.97.75-6.17h11.84c.01,2.24.26,4.28.76,6.18.84,3.21,2.8,5.33,5.82,6.3.67.22,1.35.42,2.02.62.96.29,1.93.58,2.87.9,2.6.9,4.4,1.89,5.83,3.21.54.5,1.18.82,1.95.97.06.01.12.02.18.02.03,0,.06-.01.09-.02v32.38H1.8Z" fill="#223a70" stroke-width="0"/>
            <path d="m44.16,26.06c-1.63-2.65-3.29-4.49-5.24-5.8-.57-.38-1.19-.69-1.89-.95-3.08-1.12-4.83-.4-6.25,2.57-.32.68-.56,1.37-.78,2.05-.19.58-.4,1.12-.63,1.65-.3-.22-.4-.51-.37-1,.08-1.19.07-2.4.05-3.56,0-.5-.02-.99-.02-1.49s-.4-.9-.9-.9-.9.4-.9.9,0,1.01.02,1.52c.02,1.12.04,2.29-.04,3.4-.1,1.42.47,2.39,1.73,2.95.03.14.08.27.14.4-.52.81-.53,1.74-.54,2.46v.18c-.05,2.45-.04,4.94-.04,7.35,0,1.12,0,2.25,0,3.38,0,2.99,1.21,6.75,7.01,7.7,1.61.26,3.22.31,4.8.31.45,0,.89,0,1.33,0,.66,0,1.31-.01,1.96,0,.02,0,.04,0,.06,0,2.68,0,5.57-1.79,5.62-5.76.03-2.33-.29-4.73-.97-7.35-.98-3.78-2.34-7.05-4.15-9.99Zm-.49,21.29h-.04c-.67,0-1.33,0-2,0-2.01.02-3.91.03-5.82-.28-3.76-.61-5.5-2.49-5.5-5.92,0-1.13,0-2.26,0-3.38,0-2.4-.01-4.88.03-7.31v-.19c0-.43.02-.77.09-1.06.54.53,1.02,1.15,1.54,1.87.16.23.34.38.51.5.06.34.18.64.29.89l.41.98c.62,1.48,1.26,3.01,1.84,4.49.1.54-.61,2.69-.94,3.02-.18.09-.48.13-.79.17-.19.02-.38.05-.56.09-.49.1-.8.57-.71,1.06.09.43.46.72.88.72.06,0,.12,0,.18-.02.14-.03.29-.05.44-.07.43-.06.91-.12,1.37-.36.65-.33,1.27-1.58,1.62-2.82.06.07.12.12.18.19.29.32.58.65.95.95.17.14.34.33.51.53.46.52,1.02,1.17,2.02,1.35.05,0,.11.01.16.01.43,0,.81-.31.88-.74.09-.49-.24-.96-.73-1.04-.35-.06-.59-.32-.98-.76-.21-.24-.44-.51-.73-.74-.26-.21-.49-.48-.75-.76-.35-.4-.76-.83-1.27-1.19-.02-.08-.03-.15-.06-.22-.59-1.54-1.24-3.09-1.87-4.58l-.42-1s-.02-.06-.04-.1c1.32-.32,2.48-.35,3.61-.06.2.05.4.19.64.35.42.28.94.63,1.66.66.5.03.92-.37.94-.86.02-.5-.37-.92-.86-.94-.21,0-.41-.14-.73-.35-.33-.22-.7-.47-1.19-.6-1.54-.4-3.16-.35-4.93.15-.04.01-.06.02-.09.02,0,0,0,0,0,0-.71-1-1.53-2.03-2.6-2.86-.05-.04-.1-.12-.12-.17.46-.82.74-1.68,1.02-2.51.22-.65.42-1.26.7-1.84,1.02-2.13,1.8-2.45,4.01-1.65.57.21,1.06.45,1.5.75,1.75,1.18,3.2,2.79,4.71,5.25,1.72,2.79,3.01,5.9,3.95,9.5.64,2.46.94,4.71.91,6.87-.03,2.75-1.93,3.98-3.82,3.98Z" fill="#223a70" stroke-width="0"/>
            <path d="m25.2,21.04c0-.51.02-1.01.02-1.52s-.4-.9-.9-.9-.9.4-.9.9,0,.99-.02,1.49c-.02,1.16-.04,2.36.05,3.56.03.48-.07.76-.34.98-.15-.39-.29-.8-.42-1.22-.23-.75-.47-1.52-.86-2.26-.28-.52-.57-1.03-.88-1.53-1.35-2.17-3.59-1.73-4.33-1.59-2.59.51-4.39,2.19-5.67,3.59-3.06,3.37-5.22,7.63-6.78,13.4-.82,3.03-1.1,5.59-.88,8.05.26,3.01,2.23,5.02,5,5.12l.76.03c1.08.04,2.18.08,3.28.08,1.94,0,3.91-.13,5.85-.62,2.52-.64,4.21-1.95,5.16-3.99.52-1.12.59-2.32.6-3.34.03-4,.03-7.58,0-10.94,0-.68-.02-1.66-.56-2.52.06-.13.11-.27.14-.41,1.26-.56,1.83-1.53,1.73-2.95-.08-1.12-.06-2.28-.04-3.4Zm-3.07,20.22c0,.87-.06,1.79-.44,2.6-.71,1.54-1.97,2.5-3.96,3-2.8.71-5.76.6-8.62.49l-.77-.03c-1.86-.07-3.09-1.37-3.27-3.48-.2-2.24.07-4.6.83-7.42,1.48-5.49,3.51-9.52,6.37-12.66,1.16-1.27,2.63-2.63,4.69-3.04.34-.07.63-.1.89-.1.83,0,1.24.34,1.57.87.29.47.56.95.82,1.43.31.59.52,1.24.73,1.94.22.7.44,1.43.79,2.13-.02.06-.07.14-.12.18-1.06.81-1.86,1.82-2.62,2.87-.02,0-.04-.01-.06-.02-1.72-.53-3.34-.58-4.96-.15-.49.13-.85.38-1.18.59-.32.22-.53.35-.74.36-.5.02-.88.44-.86.94.02.5.45.89.94.86.72-.03,1.24-.38,1.66-.66.24-.16.45-.3.65-.35,1.13-.3,2.28-.28,3.49.02l-.12.33c-.45,1.23-.91,2.46-1.37,3.68-.03.09-.09.17-.15.26-.09.14-.17.28-.24.42-.2.42-.3.82-.36,1.22-.73.52-1.29,1.15-1.84,1.77-.48.54-.92,1.05-1.45,1.45-.09.07-.17.14-.25.21,0,0-.02.02-.03.03-.42-.01-.81.26-.91.69-.12.48.18.97.66,1.09.13.03.25.04.36.04.56,0,.93-.34,1.13-.52.04-.03.07-.07.11-.1.68-.51,1.21-1.11,1.73-1.7.24-.27.48-.54.72-.79.15.49.35.96.53,1.39.08.2.17.39.25.59.46,1.15,1.71,1.24,2.46,1.3l.27.02s.06,0,.08,0c.46,0,.85-.35.9-.82.05-.5-.32-.93-.81-.98l-.3-.02c-.26-.02-.82-.06-.92-.17-.08-.21-.17-.42-.26-.62-.51-1.19-.95-2.21-.43-3.3.04-.08.09-.15.13-.23.12-.19.23-.38.31-.58.46-1.23.92-2.46,1.37-3.7l.35-.95c.22-.13.44-.29.61-.54.52-.72,1-1.34,1.53-1.86.08.33.1.71.1,1.13.03,3.35.04,6.92,0,10.91Z" fill="#223a70" stroke-width="0"/>
          </g>
        </g>
      </svg>,
      },
    },
    {
      title: 'Rayos X OIT',
      fields: {
        icon: <svg id="uuid-d902c34e-3377-43fe-a68d-f35b7fe8c514" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.17 54.17">
        <g id="uuid-ce017fa3-ebad-4499-8ffd-f14cacd29154" data-name="Capa 1">
          <g>
            <path d="m53.27,0H.9c-.5,0-.9.4-.9.9v52.37c0,.5.4.9.9.9h52.37c.5,0,.9-.4.9-.9V.9c0-.5-.4-.9-.9-.9Zm-.9,18.19c-.4-.09-.72-.25-1-.5-1.62-1.5-3.62-2.6-6.47-3.59-.97-.34-1.96-.63-2.95-.93-.67-.2-1.33-.4-1.99-.61-2.43-.78-3.94-2.43-4.63-5.04-.46-1.75-.68-3.63-.7-5.72h17.73v16.39ZM19.2,1.8c-.01,2.05-.24,3.93-.7,5.73-.71,2.76-2.41,4.45-5.36,5.33l-1.13.33c-1.63.47-3.32.96-4.94,1.66-1.71.73-3.17,1.63-4.47,2.73-.28.24-.54.4-.79.51V1.8h17.4ZM1.8,52.37V19.96c.69-.14,1.31-.47,1.96-1.02,1.16-.99,2.47-1.79,4.01-2.45,1.52-.65,3.16-1.13,4.74-1.58l1.14-.33c3.57-1.06,5.73-3.22,6.6-6.61.5-1.94.74-3.97.75-6.17h11.84c.01,2.24.26,4.28.76,6.18.84,3.21,2.8,5.33,5.82,6.3.67.22,1.35.42,2.02.62.96.29,1.93.58,2.87.9,2.6.9,4.4,1.89,5.83,3.21.54.5,1.18.82,1.95.97.06.01.12.02.18.02.03,0,.06-.01.09-.02v32.38H1.8Z" fill="#223a70" stroke-width="0"/>
            <path d="m44.16,26.06c-1.63-2.65-3.29-4.49-5.24-5.8-.57-.38-1.19-.69-1.89-.95-3.08-1.12-4.83-.4-6.25,2.57-.32.68-.56,1.37-.78,2.05-.19.58-.4,1.12-.63,1.65-.3-.22-.4-.51-.37-1,.08-1.19.07-2.4.05-3.56,0-.5-.02-.99-.02-1.49s-.4-.9-.9-.9-.9.4-.9.9,0,1.01.02,1.52c.02,1.12.04,2.29-.04,3.4-.1,1.42.47,2.39,1.73,2.95.03.14.08.27.14.4-.52.81-.53,1.74-.54,2.46v.18c-.05,2.45-.04,4.94-.04,7.35,0,1.12,0,2.25,0,3.38,0,2.99,1.21,6.75,7.01,7.7,1.61.26,3.22.31,4.8.31.45,0,.89,0,1.33,0,.66,0,1.31-.01,1.96,0,.02,0,.04,0,.06,0,2.68,0,5.57-1.79,5.62-5.76.03-2.33-.29-4.73-.97-7.35-.98-3.78-2.34-7.05-4.15-9.99Zm-.49,21.29h-.04c-.67,0-1.33,0-2,0-2.01.02-3.91.03-5.82-.28-3.76-.61-5.5-2.49-5.5-5.92,0-1.13,0-2.26,0-3.38,0-2.4-.01-4.88.03-7.31v-.19c0-.43.02-.77.09-1.06.54.53,1.02,1.15,1.54,1.87.16.23.34.38.51.5.06.34.18.64.29.89l.41.98c.62,1.48,1.26,3.01,1.84,4.49.1.54-.61,2.69-.94,3.02-.18.09-.48.13-.79.17-.19.02-.38.05-.56.09-.49.1-.8.57-.71,1.06.09.43.46.72.88.72.06,0,.12,0,.18-.02.14-.03.29-.05.44-.07.43-.06.91-.12,1.37-.36.65-.33,1.27-1.58,1.62-2.82.06.07.12.12.18.19.29.32.58.65.95.95.17.14.34.33.51.53.46.52,1.02,1.17,2.02,1.35.05,0,.11.01.16.01.43,0,.81-.31.88-.74.09-.49-.24-.96-.73-1.04-.35-.06-.59-.32-.98-.76-.21-.24-.44-.51-.73-.74-.26-.21-.49-.48-.75-.76-.35-.4-.76-.83-1.27-1.19-.02-.08-.03-.15-.06-.22-.59-1.54-1.24-3.09-1.87-4.58l-.42-1s-.02-.06-.04-.1c1.32-.32,2.48-.35,3.61-.06.2.05.4.19.64.35.42.28.94.63,1.66.66.5.03.92-.37.94-.86.02-.5-.37-.92-.86-.94-.21,0-.41-.14-.73-.35-.33-.22-.7-.47-1.19-.6-1.54-.4-3.16-.35-4.93.15-.04.01-.06.02-.09.02,0,0,0,0,0,0-.71-1-1.53-2.03-2.6-2.86-.05-.04-.1-.12-.12-.17.46-.82.74-1.68,1.02-2.51.22-.65.42-1.26.7-1.84,1.02-2.13,1.8-2.45,4.01-1.65.57.21,1.06.45,1.5.75,1.75,1.18,3.2,2.79,4.71,5.25,1.72,2.79,3.01,5.9,3.95,9.5.64,2.46.94,4.71.91,6.87-.03,2.75-1.93,3.98-3.82,3.98Z" fill="#223a70" stroke-width="0"/>
            <path d="m25.2,21.04c0-.51.02-1.01.02-1.52s-.4-.9-.9-.9-.9.4-.9.9,0,.99-.02,1.49c-.02,1.16-.04,2.36.05,3.56.03.48-.07.76-.34.98-.15-.39-.29-.8-.42-1.22-.23-.75-.47-1.52-.86-2.26-.28-.52-.57-1.03-.88-1.53-1.35-2.17-3.59-1.73-4.33-1.59-2.59.51-4.39,2.19-5.67,3.59-3.06,3.37-5.22,7.63-6.78,13.4-.82,3.03-1.1,5.59-.88,8.05.26,3.01,2.23,5.02,5,5.12l.76.03c1.08.04,2.18.08,3.28.08,1.94,0,3.91-.13,5.85-.62,2.52-.64,4.21-1.95,5.16-3.99.52-1.12.59-2.32.6-3.34.03-4,.03-7.58,0-10.94,0-.68-.02-1.66-.56-2.52.06-.13.11-.27.14-.41,1.26-.56,1.83-1.53,1.73-2.95-.08-1.12-.06-2.28-.04-3.4Zm-3.07,20.22c0,.87-.06,1.79-.44,2.6-.71,1.54-1.97,2.5-3.96,3-2.8.71-5.76.6-8.62.49l-.77-.03c-1.86-.07-3.09-1.37-3.27-3.48-.2-2.24.07-4.6.83-7.42,1.48-5.49,3.51-9.52,6.37-12.66,1.16-1.27,2.63-2.63,4.69-3.04.34-.07.63-.1.89-.1.83,0,1.24.34,1.57.87.29.47.56.95.82,1.43.31.59.52,1.24.73,1.94.22.7.44,1.43.79,2.13-.02.06-.07.14-.12.18-1.06.81-1.86,1.82-2.62,2.87-.02,0-.04-.01-.06-.02-1.72-.53-3.34-.58-4.96-.15-.49.13-.85.38-1.18.59-.32.22-.53.35-.74.36-.5.02-.88.44-.86.94.02.5.45.89.94.86.72-.03,1.24-.38,1.66-.66.24-.16.45-.3.65-.35,1.13-.3,2.28-.28,3.49.02l-.12.33c-.45,1.23-.91,2.46-1.37,3.68-.03.09-.09.17-.15.26-.09.14-.17.28-.24.42-.2.42-.3.82-.36,1.22-.73.52-1.29,1.15-1.84,1.77-.48.54-.92,1.05-1.45,1.45-.09.07-.17.14-.25.21,0,0-.02.02-.03.03-.42-.01-.81.26-.91.69-.12.48.18.97.66,1.09.13.03.25.04.36.04.56,0,.93-.34,1.13-.52.04-.03.07-.07.11-.1.68-.51,1.21-1.11,1.73-1.7.24-.27.48-.54.72-.79.15.49.35.96.53,1.39.08.2.17.39.25.59.46,1.15,1.71,1.24,2.46,1.3l.27.02s.06,0,.08,0c.46,0,.85-.35.9-.82.05-.5-.32-.93-.81-.98l-.3-.02c-.26-.02-.82-.06-.92-.17-.08-.21-.17-.42-.26-.62-.51-1.19-.95-2.21-.43-3.3.04-.08.09-.15.13-.23.12-.19.23-.38.31-.58.46-1.23.92-2.46,1.37-3.7l.35-.95c.22-.13.44-.29.61-.54.52-.72,1-1.34,1.53-1.86.08.33.1.71.1,1.13.03,3.35.04,6.92,0,10.91Z" fill="#223a70" stroke-width="0"/>
          </g>
        </g>
      </svg>,
      },
    },
    {
      title: 'Rx lumbosacra',
      fields: {
        icon: 'icon-lumbosacral',
      },
    },
    {
      title: 'Odontología',
      fields: {
        icon: 'icon-tooth',
      },
    },
    {
      title: 'Músculo esquelético diferenciado',
      fields: {
        icon: 'icon-musculoskeletal',
      },
    },
    {
      title: 'Prueba de esfuerzo',
      fields: {
        icon: 'icon-stress-test',
      },
    },
    {
      title: 'MAPA',
      fields: {
        icon: 'icon-ekg-heart-card',
      },
    },
    {
      title: 'Laboratorio',
      slug: 'examenes-de-laboratorio',
      fields: {
        icon: 'icon-drop-tube',
      },
    },
    {
      title: 'Exámenes Binacionales',
      slug: 'examenes-binacionales',
      fields: {
        icon: 'icon-health-hexagon',
      },
    },
    ...otherBatteries,
  ];


  useEffect(() => {
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    const formId = '8d114b5b-41eb-4f97-9e94-3e2292617196';

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId,
          target: '#hubspotForm',
        });
      }
    });
  }, []);

  const createTags = (from) => {
    const titles = {
      componentTemplate: [
        {
          tag: 'h4',
          className: 'text-center mb-lg-40 mb-md-20 mb-sm-20 mb-xs-20',
          text: 'Personaliza tu protocolo'
        },
        {
          tag: 'h5',
          className: 'text-center mb-lg-60 mb-md-30 mb-sm-30 mb-xs-30',
          text: 'En Centro Médico del Trabajador nos adaptamos a las necesidades de tu empresa, realizando protocolos personalizados por cada puesto de trabajo según el requerimiento de su área médica.'
        },
        {
          tag: 'p',
          className: 'sub-title text-center mb-40',
          text: 'Te invitamos a revisar nuestro amplio listado de <italic>exámenes disponibles</italic>'
        },
      ],
      pageTemplate: [
        {
          tag: 'h1',
          className: 'text-center mb-lg-40 mb-md-20 mb-sm-20 mb-xs-20',
          text: 'Personaliza tu protocolo'
        },
        {
          tag: 'h2',
          className: 'text-center mb-lg-60 mb-md-30 mb-sm-30 mb-xs-30',
          text: 'En Centro Médico del Trabajador nos adaptamos a las necesidades de tu empresa, realizando protocolos personalizados por cada puesto de trabajo según el requerimiento de su área médica.'
        },
        {
          tag: 'h3',
          className: 'text-center mb-40',
          text: 'Te invitamos a revisar nuestro amplio listado de <italic>exámenes disponibles</italic>'
        },
      ]
    }

    return titles[from].map((item, index) =>
      createElement(
        item.tag,
        {
          key: index,
          className: item.className,
          ...(item.text.includes('<') ? { dangerouslySetInnerHTML: { __html: item.text } } : {}),
        },
        !item.text.includes('<') ? item.text : null,
      ));
  };


  return (
    <Row
      id='protocolCardsContainer'
      justify='center'
      className={`${from === 'componentTemplate' && 'pt-80 pb-40'} protocol-cards-container`}
    >
      <Col lg={24} xs={22}>
      {
        createTags(from)
      }
      </Col>
      <Col lg={24} xs={22}>
        <Row justify='center' gutter={[30, 20]} className='exam-card-wrapper'>
        {
          allCards.map((item, index) => {
            if (item.slug) {
              return (
                <Col lg={6} xs={24} key={index} className='mb-lg-30'>
                  <Link
                    to={(item.fields.exams || item.slug === 'otros-examenes' || !['examenes-de-laboratorio', 'examenes-binacionales'].includes(item.slug) ) ? `/examenes/${item.slug}/` : `/${item.slug}/`}
                    className='link-item-container'
                  >
                    <div className='card-wrapper'>
                      <Row justify='center' className='card-item pr-lg-15 pl-lg-15' align='middle'>
                        <Col lg={22} md={2} sm={4} xs={6}>
                          <Row justify='center' align='middle'>
                            <Col className='icon-wrapper'>
                              <span className={item.fields.icon} title={item.title} />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={24} md={20} sm={18} xs={16} className='mt-lg-30'>
                          <p className='mb-0'>{item.title ?? item.fields.title}</p>
                        </Col>
                        <Col className='see-more-wrapper mt-30'>
                          <Link to={(item.fields.exams || item.slug === 'otros-examenes' || !['examenes-de-laboratorio', 'examenes-binacionales'].includes(item.slug) ) ? `/examenes/${item.slug}/` : `/${item.slug}/`}>
                            Ver más
                          </Link>
                        </Col>
                        <Col xs={2} className='chevron-mobile-wrapper'>
                          <span className='icon-system-chevron-right' />
                        </Col>
                      </Row>
                      <Row className='orange-bar-desktop' />
                    </div>
                  </Link>
                </Col>
              )
            } else {
              return (
                <Col lg={6} xs={24} key={index} className='mb-lg-30'>
                  <div className='non-link-item'>
                    <Row justify='center' className='card-item pr-lg-15 pl-lg-15' align='middle'>
                      <Col lg={22} md={2} sm={4} xs={6}>
                        <Row justify='center' align='middle'>
                        {
                          React.isValidElement(item.fields.icon) ? (
                            <Col className='icon-wrapper'>
                              {item.fields.icon}
                            </Col>
                          ) : (
                            <Col className='icon-wrapper'>
                              <span className={item.fields.icon} title={item.title} />
                            </Col>
                          )
                        }
                        </Row>
                      </Col>
                      <Col lg={24} md={20} sm={18} xs={16}>
                        <p className='mb-0'>{item.title ?? item.fields.title}</p>
                      </Col>
                      <Col xs={2} />
                    </Row>
                    <Row className='orange-bar-desktop' />
                  </div>
                </Col>
              )
            }
          })
        }
        </Row>
      </Col>
      {
        (from === 'pageTemplate' && type) &&
          <Col lg={24} xs={24} className='form-wrapper mt-lg-110 mt-md-60 mt-sm-60 mt-xs-60'>
            <Row justify="center">
              <Col lg={24} xs={20}>
                <h4 className="text-center mb-lg-30 mb-md-20 mb-sm-20 mb-xs-20">Si necesitas  contactarte con nosotros</h4>
              </Col>
            </Row>
            <HubspotForm type={type} country={countryCode} />
          </Col>
      }
    </Row>
  );
};

export default ProtocolCards;